import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a114f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-2xl my-3" }
const _hoisted_2 = { class: "my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_filter_table = _resolveComponent("filter-table")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_customer_detail = _resolveComponent("customer-detail")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!

  return (_openBlock(), _createBlock(_component_section_layout_content, _normalizeProps(_guardReactiveProps(_ctx.content)), {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 24,
            class: "mb-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, "Stock " + _toDisplayString(_ctx.state.warehouse.name), 1),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.state.warehouse.address), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_filter_table)
        ]),
        _: 1
      }),
      _createVNode(_component_customer_detail, {
        isLoading: _ctx.isLoading,
        warehouseId: _ctx.warehouseId
      }, null, 8, ["isLoading", "warehouseId"])
    ]),
    _: 1
  }, 16))
}