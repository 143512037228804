
import { defineComponent, ref } from 'vue'
import { Edit } from '@element-plus/icons'
import ImageViewDialog from './ImageViewDialog.vue'
import { useNotification } from '@/composables'

import productDataService from '@/services/productDataService'

export default defineComponent({
  components: {
    Edit,
    ImageViewDialog,
  },
  setup() {
    const { error } = useNotification()

    const state = productDataService.state
    const imageViewDialog = ref<InstanceType<typeof ImageViewDialog>>()

    const view = (path: string) => {
      path ? imageViewDialog.value?.view(path) : error('URL not found')
    }
    return { state, view, imageViewDialog }
  },
})
