import { reactive, ref } from 'vue'
import { useResource } from '@/services'
import { AxiosResponse } from 'axios'
import { IWarehouse,IStocksType, Params, IStatus } from '@/interfaces/warehouse'

class WarehouseDataService {
    private resource: string;
    public  state:  IStatus;
    constructor(){
        this.resource = "warehouses"
        this.state = reactive<IStatus>({
            
            total: 0,
            isLoading: false,
            isSubmited: false,
            warehouses: [] as IWarehouse[],             
            warehouse: {
                name: '',
                code: '',
                stockType: '',
                address: '',
                city: '',
                province: '',
                country: '',
                zipCode: '',
                phone: '',
                activated: true
            } as IWarehouse,
            stocksType: [] as IStocksType[],
            multipleSelection: [],
            params:{
                search:'',
                page: 1,
                limit: 100
            } as Params,
            isContentShown: false,
          })
        
    }
    async getAll(params: Params) {
        const { getAll } = useResource(this.resource);
        try {
            return await getAll(params) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async getStockType(params: any) {
        const { getAll } = useResource(`${this.resource}/stock-type/all`);
        try {
            return await getAll(params) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async get(id: string | string[]) {
        const { get } = useResource(this.resource);
        try {
            return await get(id) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async create(warehouse: IWarehouse) {
        const { add } = useResource(this.resource);
        try {
            return await add(warehouse) as AxiosResponse
        } catch (error ) {
            return error as AxiosResponse
        }
    }
    async update( warehouse: IWarehouse) {
        const { edit } = useResource(`${this.resource}/${warehouse._id}`);
        try {
            return await edit(warehouse) as AxiosResponse
        } catch (error ) {
            return error as AxiosResponse
        }
    }

    async deleteById(id: string | string[]) {
        const { deleteById } = useResource(this.resource);
        try {
            return await deleteById(id) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async getProductsInStock(params: Params){
        const { fetch } = useResource(`${this.resource}/products`);
        try {
            return await fetch(params) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async findByTitle(title: string) {
    //return http.get(`/tutorials?title=${title}`);
    }
}
export default new WarehouseDataService();