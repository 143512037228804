
import productDataService from '@/services/productDataService'
import { IProductWarehouse } from '@/interfaces/product-warehouse'
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useNotification } from '@/composables'
import { ElMessageBox } from 'element-plus'
import ImageViewDialog from './ImageViewDialog.vue'
import ProductWarehouseDetail from './ProductWarehouseDetail.vue'
import warehouseDataService from '@/services/warehouseDataService'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  name: 'List',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageViewDialog, ProductWarehouseDetail, Pagination },
  setup() {
    const state = productDataService.state
    const { error, success } = useNotification()

    const getProductsInStock = async (): Promise<void> => {
      // if (warehouseDataService.state.warehouseId)
      // state.params.warehouseId = warehouseDataService.state.warehouseId
      // const products: AxiosResponse =
      //   await warehouseDataService.getProductsInStock(state.params)
      const products: AxiosResponse = await productDataService.getAll(
        state.params
      )
      console.log(products)
      state.products = products.data as IProductWarehouse[]
      state.isLoading = false
    }
    onMounted(() => {
      state.isLoading = true
      count()
      getProductsInStock()
    })
    const totalProduct = ref()
    const count = async () => {
      totalProduct.value = (await productDataService.count())?.data
    }
    const productDetail = async (id: string): Promise<void> => {
      state.isSubmited = productDataService.ACTION.DETAIL
      //state.warehouse = state.warehouses.find(
      //  (v: IProductWarehouse) => v._id === id
      //)
      // createDialog.value?.open()
      state.product = {
        ...state.products.find((v: any) => v._id === id),
      } as IProductWarehouse
      state.catalogId = state.product.catalogId
      state.isContentShown = true
      state.formTitle = state.product.title
    }
    watch(state.params, getProductsInStock)

    const imageViewDialog = ref<InstanceType<typeof ImageViewDialog>>()

    const view = (path: string) => {
      console.log(path)
      path ? imageViewDialog.value?.view(path) : error('URL not found')
    }
    return {
      state,
      moment,
      view,
      imageViewDialog,
      productDetail,
      totalProduct,
    }
  },
})
