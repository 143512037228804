
import { defineComponent, ref, computed, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { useRoute } from 'vue-router'
import WarehouseDataService from '@/services/warehouseDataService'
import { AxiosResponse } from 'axios'
import { IWarehouse } from '@/interfaces/warehouse'
import CustomerDetail from '../../seller/products-warehouse/components/CustomerDetail.vue'
export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    CustomerDetail,
  },
  setup() {
    const breadcrumbs = ['Warehouse', 'Detail']
    const router = useRoute()
    const state = WarehouseDataService.state
    state.warehouseId = router.params?.id as string

    const getWarehouseById = async () => {
      const data: AxiosResponse = await WarehouseDataService.get(
        state.warehouseId as string
      )
      state.isLoading = false
      state.warehouse = { ...data.data }
    }
    onMounted(() => {
      getWarehouseById()
    })
    const content = computed(() => {
      return { breadcrumbs }
    })
    return { content, state }
  },
})
